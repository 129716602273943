export const paths = {
  ACCOUNT: 'account',
  ANALYTICS: 'analytics',
  BASE: '/',
  DASHBOARD: 'dashboard',
  DATA_MANAGEMENT: 'data-management',
  DOMESTIC_DASHBOARD: 'domestic-dashboard',
  EVENTS_SUMMARY_UI: 'events-summary',
  FLEX_TRADE_MANAGER: 'flex-trade-manager',
  GITBOOK: 'help',
  JAPAN_MARKETS: 'markets',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REPORTING: 'reports',
  SCHEDULES: 'schedules',
  USER_MANAGEMENT: 'user-management',
  FLEX_BIDDER: 'flex-bidder',
  ONBOARDING: 'onboarding',
} as const;

export const frontEnds = {
  analyticsUi: {
    serviceUrl: `/${paths.ANALYTICS}`,
  },
  changePassword: {
    serviceUrl: `/${paths.ACCOUNT}`,
  },
  dataManagement: {
    moduleResource: '/static-data-management',
    serviceUrl: `/${paths.DATA_MANAGEMENT}`,
  },
  domesticDashboard: {
    moduleResource: '/domestic/core',
    serviceUrl: `/${paths.DOMESTIC_DASHBOARD}`,
  },
  eventsSummary: {
    moduleResource: '/events',
    serviceUrl: `/${paths.EVENTS_SUMMARY_UI}`,
  },
  flexConsole: {
    moduleResource: '/dispatch-and-control/core',
    serviceUrl: `/${paths.DASHBOARD}`,
  },
  flexBidder: {
    moduleResource: '/flex-bidder',
    serviceUrl: `/${paths.FLEX_BIDDER}`,
  },
  flexTradeManager: {
    moduleResource: '/trade-manager/flex-ui',
    serviceUrl: `/${paths.FLEX_TRADE_MANAGER}`,
  },
  gitbook: {
    serviceUrl: `/${paths.GITBOOK}`,
  },
  japanMarkets: {
    moduleResource: '/japan-markets',
    serviceUrl: `/${paths.JAPAN_MARKETS}`,
  },
  login: {
    serviceUrl: `/${paths.LOGIN}`,
  },
  logout: {
    serviceUrl: `/${paths.LOGOUT}`,
  },
  onboarding: {
    moduleResource: '/onboarding',
    serviceUrl: `/${paths.ONBOARDING}`,
  },
  reports: {
    moduleResource: '/reporting',
    serviceUrl: `/${paths.REPORTING}`,
  },
  schedules: {
    moduleResource: '/schedules',
    serviceUrl: `/${paths.SCHEDULES}`,
  },
  userManagement: {
    moduleResource: '/user-management',
    serviceUrl: `/${paths.USER_MANAGEMENT}`,
  },
} as const;

/**
 * single-spa doesn't seem to provide a nice way of getting the base path as defined in the route template,
 * hence these duplicated paths which need to be passed down to some of the apps in order for their routing to
 * work.
 */
export const basePaths: Record<string, string> = {
  '@upside-spa/analytics-ui': frontEnds.analyticsUi.serviceUrl,
  '@upside-spa/data-management-ui': frontEnds.dataManagement.serviceUrl,
  '@upside-spa/domestic-dashboard-ui': frontEnds.domesticDashboard.serviceUrl,
  '@upside-spa/flex-console-ui': frontEnds.flexConsole.serviceUrl,
  '@upside-spa/gitbook-ui': frontEnds.gitbook.serviceUrl,
  '@upside-spa/japan-markets-viewer': frontEnds.japanMarkets.serviceUrl,
  '@upside-spa/flex-bidder-ui': frontEnds.flexBidder.serviceUrl,
  '@upside-spa/asset-onboarding-ui': frontEnds.onboarding.serviceUrl,
  '@upside-spa/reports-ui': frontEnds.reports.serviceUrl,
  '@upside-spa/schedules-ui-v2': frontEnds.schedules.serviceUrl,
  '@upside-spa/user-management-ui': frontEnds.userManagement.serviceUrl,
};

export const appHeaderAppName = '@upside-spa/app-header';
export const navigationAppName = '@upside-spa/navigation';
export const infraflexAiAssistantAppName = '@upside-spa/infraflex-assistant-ui';
export const devToolsAppName = '@upside-spa/dev-tools';
