import { ApplicationContext, StandardEvents } from '@upside/spa-shared-context';

import { basePaths, devToolsAppName } from 'shell/const';
import { getAppStatus, registerApplication, LifeCycles } from 'single-spa';

import application from '../application';

/**
 * Registers dev tools when the modal is opened for the first time
 */
export default (context: ApplicationContext): void => {
  context.eventBus.on(StandardEvents.SetDevToolsModalState, () => {
    // Only register the dev tools app if it's not already registered
    if (!getAppStatus(devToolsAppName)) {
      registerApplication({
        activeWhen: () => context.authentication.loggedIn,
        app: () => System.import(devToolsAppName) as Promise<LifeCycles>,
        name: devToolsAppName,
        customProps: {
          basePath: basePaths[application.name],
          context,
        },
      });
    }
  });
};
